* {
  border-radius: 3px !important;
}

:root {
  --font: #f1f5ff;
  --font-supplemental: #818694;
  --lenovo: #3b83d1;
  --bg: #0c0d21;
  --fg: rgba(129, 134, 148, 0.13);
  --font-card: #d1d5e0;
}

body {
  background-color: var(--bg);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px 50px;
}

.header {
  width: 734px;
}

.header-menu {
  width: 50px;
  height: 50px;
  background-color: var(--fg);
  border-radius: 10px !important;
}

.deny-modal {
  padding: 60px 75px;
  width: 528px;
  height: 290px;
  background: #202134;
  border: 1px solid var(--lenovo);
}

.off-canvas {
  background: #202134;
  padding: 45px 24px;
}

.outlined-btn {
  min-width: 144px;
  height: 51px;
  padding: 16px 50px 16px 50px;
  border: 2px solid var(--lenovo);
  color: var(--font);
}

.filled-btn {
  min-width: 144px;
  height: 51px;
  padding: 16px 50px 16px 50px;
  background-color: var(--lenovo);
  color: var(--font);
}

.width-205 {
  width: 205px !important;
}

.accept-btn {
  width: 208.76px;
  height: 51px;
  padding: 16px 50px 16px 50px;
}

.raffle-btn {
  height: 51px;
  padding: 16px 50px 16px 50px;
}

.nav-btn {
  width: 238px;
  height: 51px;
  padding: 16px 50px 16px 50px;
  border: none;
}

.request-card {
  width: 734px;
  height: 197px;
  padding: 24px 16px;
}

.profile-disk {
  width: 52px;
  height: 52px;
  border-radius: 50% !important;
}

.profile-disk-lg {
  width: 100px;
  height: 100px;
  border-radius: 50% !important;
}

.square-77 {
  height: 77px;
  width: 77px;
}

.time-editor {
  padding: 12px 24px;
  width: 278px;
  height: 49px;
}

.input-search-field-container {
  width: 238px;
  height: 51px;
}

.input-search-field {
  width: auto;
  background: none;
  border: none;
}

.input-search-field:focus {
  outline: none;
}

.pending-dashboard-container {
  background-color: var(--fg);
  width: 734px;
  height: 589px;
  padding: 24px 16px;
}

.pending-request-image {
  width: 702px;
  height: 369px;
}

.page-layout {
  display: contents;
}

.challenge-card-container {
  height: calc(262px + 180px - 53px);
  width: 334px;
}

.challenge-card {
  background-color: var(--fg);
  width: 100%;
  height: 262px;
  padding: 20px 25px;
}

.text-lenovo {
  color: var(--lenovo);
  cursor: pointer;
}

.challenge-card-img {
  width: 317px;
  height: 180px;
}

.challenge-card-game-title {
  background: rgba(59, 131, 209, 0.72);
  width: 317px;
  height: 46px;
}

.challenge-card-font {
  color: var(--font-card);
}

.challenge-card-date {
  background: rgba(129, 134, 148, 0.13);
  width: fit-content;
  height: 30px;
  margin-top: 9px;
  margin-bottom: 21px;
  margin-left: -25px;
  padding: 0px 25px;
}

.game-card-container {
  width: 317px;
  height: 180px;
}

.game-card-overlay {
  background: rgba(59, 131, 209, 0.89);
  margin-top: -180px;
  padding: 24px;
}

.landing-page-bg {
  position: absolute;
  z-index: -1;
  left: 0px;
  top: 0px;
}

.landing-page-dimensions {
  height: 80vh;
}

.login-buttons {
  width: 419px;
}

.input-container {
  width: 419px;
  height: 49px;
  border: 1px solid var(--font-supplemental);
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

.input-container input {
  color: var(--font);
  background: none;
  border: none;
}

.input-container input:focus {
  outline: none;
}

.user-info-container {
  width: 734px;
  height: 132px;
  background-color: var(--fg);
  padding: 16px 24px;
}

.userChallengeCard-logo {
  border-radius: 5px !important;
  height: 85px;
  width: 85px;
}

.action-log-container {
  width: 734px;
  min-height: 40px;
  background-color: var(--fg);
  padding: 10px 16px;
}

.score-value-field {
  background: none;
  color: var(--font);
  width: 50px;
  border: none;
}

.score-value-field:focus {
  outline: none;
}

.offcanvas-tiles {
  width: 317px;
  height: 180px;
  background-color: var(--lenovo);
  padding: 24px;
  color: var(--font);
  font-weight: 700;
  font-size: 28px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}


.modal-content {
  width: 100%;
}

.deny-btn {
  background: none;
}

.deny-btn:hover {
  background: none;
  border: 2px solid var(--lenovo);
}

.deny-btn:disabled {
  background: none;
  border: 2px solid var(--lenovo);
}


.deny-btn:active {
  border: 2px solid var(--lenovo) !important;
  background: var(--lenovo) !important;
}

.spinnerrr {
  border-radius: 50% !important;
}

.raffle-title {
  background-color: var(--fg);
  color: var(--font-card);
  padding: 10px 25px;
  margin-bottom: 10px;
}


.raffle-card {
  background-color: var(--fg);
  color: var(--font-card);
  width: 734px;
  padding: 40px 25px;
  margin-bottom: 10px;
}