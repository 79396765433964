.theme-font-light {
    color: #F1F5FF;
}

.theme-font-supplemental {
    color: #818694;
}

.text-48 {
    font-size: 48px;
}

.text-38 {
    font-size: 38px;
}

.text-28 {
    font-size: 28px;
}

.text-24 {
    font-size: 24px;
}

.text-22 {
    font-size: 22px;
}

.text-20 {
    font-size: 20px;
}

.text-18 {
    font-size: 18px;
}

.text-16 {
    font-size: 16px;
}

.text-15 {
    font-size: 15px;
}

.text-14 {
    font-size: 14px;
}


.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}


.height-17 {
    height: 17px;
}

.height-80 {
    height: 80px;
}

.height-30 {
    height: 30px;
}

.height-15 {
    height: 15px;
}

.height-53 {
    height: 53px;
}

.width-10 {
    width: 10px;
}

.width-24 {
    width: 24px;
}

.width-33 {
    min-width: 40%;
}

.rounded-50 {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

.width-734 {
    width: 734px;
    ;
}